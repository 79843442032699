var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return this.$route.params.id == undefined ? _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_vm.hasRight('QR_WRITE') ? _c('v-btn', _vm._b({
    attrs: {
      "title": "Új igénylés"
    },
    on: {
      "click": function ($event) {
        return _vm.showRequestDialog();
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-plus")])], 1) : _vm._e()], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("QR kód igénylések")]), _c('v-card', [_c('v-data-table', {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "item-class": _vm.itemClass,
      "calculate-widths": ""
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.user`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.user.name) + " ")];
      }
    }, {
      key: `item.request_date`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on;
              return [_c('div', _vm._g({}, on), [_vm._v(_vm._s(_vm.$moment(item.request_date).format(_vm.$config.momentFormats.shortDateTime)))])];
            }
          }], null, true)
        }, [_c('div', [_vm._v(_vm._s(_vm.$moment(item.request_date).format(_vm.$config.momentFormats.explicit)))])])];
      }
    }, {
      key: `item.groups`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-chip-group', _vm._l(item.groups, function (group) {
          return _c('v-chip', {
            key: group.id,
            staticClass: "ma-1",
            attrs: {
              "small": "",
              "label": "",
              "link": "",
              "color": group.color,
              "to": {
                name: 'GroupEditor',
                params: {
                  id: group.element_id
                }
              }
            }
          }, [_vm._v(" " + _vm._s(group.name) + " ")]);
        }), 1)];
      }
    }, {
      key: `item.amount`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.email_sent_amount) + " / " + _vm._s(item.amount) + " ")];
      }
    }, {
      key: `item.approved`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [item.approved == 1 ? _c('v-icon', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v("mdi-checkbox-marked")]) : _c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v("mdi-close-box")])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [item.approved == 0 && item.user_id == _vm.$store.state.user.element_id ? _c('v-btn', {
          staticClass: "rounded-sm m-1",
          attrs: {
            "fab": "",
            "small": "",
            "color": "primary",
            "text": "",
            "title": "Igénylés módosítása"
          },
          on: {
            "click": function ($event) {
              return _vm.showRequestDialog(item.id);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1) : _vm._e(), item.approved == 1 ? _c('v-btn', {
          staticClass: "rounded-sm m-1",
          attrs: {
            "title": "Vonalkódok beállítása",
            "fab": "",
            "small": "",
            "color": "primary",
            "text": "",
            "to": {
              name: 'QrCodeRequestEditor',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-cog")])], 1) : _vm._e(), item.approved == 0 && item.user_id == _vm.$store.state.user.element_id ? _c('v-btn', {
          staticClass: "rounded-sm m-1",
          attrs: {
            "title": "Igénylés törlése",
            "fab": "",
            "small": "",
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function ($event) {
              return _vm.deleteRequest(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1), _vm.requestDialog.visible ? _c('request-dialog', {
    attrs: {
      "request-id": _vm.requestDialog.requestID
    },
    on: {
      "close": _vm.hideRequestDialog,
      "save": _vm.requestDialogSaveEventHandler
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }