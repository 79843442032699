var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "value": true,
      "width": "500"
    },
    on: {
      "click:outside": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-card-title', [_vm.requestId ? [_vm._v("Vonalkódigénylés módosítása")] : [_vm._v("Vonalkód igénylés")], _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "small": "",
      "fab": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 2), _c('v-divider', {
    staticClass: "mb-6"
  }), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-masked-text-field', {
    key: `quantity-${_vm.form.id}`,
    attrs: {
      "label": "Darabszám",
      "error-messages": _vm.errors.amount,
      "mask": _vm.$config.inputMasks.createNumberMask({
        max: 100
      }),
      "filled": ""
    },
    model: {
      value: _vm.form.amount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  }), _c('v-textarea', {
    attrs: {
      "label": "Megjegyzés",
      "error-messages": _vm.errors.comment,
      "filled": ""
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Csoport",
      "error-messages": _vm.errors.group_id,
      "items": _vm.groups,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual,
      "clearable": ""
    },
    model: {
      value: _vm.form.group_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "group_id", $$v);
      },
      expression: "form.group_id"
    }
  })], 1), _c('v-divider', {
    staticClass: "mb-0 pb-0"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "align": "start",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" Mentés ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }